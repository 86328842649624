import { InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import React from 'react';
import { sortOrder, sortOrderState } from './atoms/savedCars';
import { getRegistrationDate } from './types/Car';
import { userHasOppkjoperRole } from './atoms/auth';
import { useAtom, useAtomValue } from 'jotai';

const CarSorting = () => {
  const [sort, setSort] = useAtom(sortOrder);
  const isOppkjoper = useAtomValue(userHasOppkjoperRole);

  const standardSortTypes: Array<sortOrderState> = [
    {
      selector: (car) => getRegistrationDate(car),
      reverse: true,
      name: 'regDateFirst',
    },
    {
      selector: (car) => getRegistrationDate(car),
      reverse: false,
      name: 'regDateLast',
    },
    {
      selector: (car) => car.title || '',
      reverse: false,
      name: 'name',
    },
    {
      selector: (car) => car.price || 0,
      reverse: false,
      name: 'priceLowHigh',
    },
    {
      selector: (car) => car.price || 0,
      reverse: true,
      name: 'priceHighLow',
    },
  ];

  const sortTypes = isOppkjoper
    ? standardSortTypes
    : standardSortTypes.concat([
        {
          selector: (car) => car.deliveryDate!,
          reverse: false,
          name: 'deliveryDateLowHigh',
        },
        {
          selector: (car) => car.deliveryDate!,
          reverse: true,
          name: 'deliveryDateHighLow',
        },
      ]);

  const onSortChange = (e: SelectChangeEvent) => {
    const value = e.target.value as string;
    if (value) {
      const sortOrder = sortTypes.find((x) => x.name === value);
      if (sortOrder) {
        setSort(sortOrder);
      }
    }
  };

  return (
    <div>
      <FormControl variant="outlined" sx={{ minWidth: 165 }}>
        <InputLabel id="sort-label">Sortering</InputLabel>
        <Select
          label="Sortering"
          variant={'outlined'}
          value={sort.name}
          labelId="sort-label"
          name="sort"
          onChange={onSortChange}
        >
          <MenuItem value="name">Navn</MenuItem>
          <MenuItem value="priceLowHigh">Pris lav høy</MenuItem>
          <MenuItem value="priceHighLow">Pris høy lav</MenuItem>
          <MenuItem value="regDateFirst">Alder (stigende)</MenuItem>
          <MenuItem value="regDateLast">Alder (synkende)</MenuItem>
          {!isOppkjoper && <MenuItem value="deliveryDateLowHigh">Leveringstid først</MenuItem>}
          {!isOppkjoper && <MenuItem value="deliveryDateHighLow">Leveringstid sist</MenuItem>}
        </Select>
      </FormControl>
    </div>
  );
};

export default CarSorting;
