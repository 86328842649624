import React from 'react';
import { userRequestId } from '../atoms/users';
import { Button, Paper, TextField } from '@mui/material';
import c from './ManageUsersPage.module.css';
import { useForm } from 'react-hook-form';
import SaveIcon from '@mui/icons-material/Save';
import { useAuth0 } from '@auth0/auth0-react';
import { useSnackbar } from 'notistack';
import Loader from '../Loader';
import { ExistingUsersOverview } from './ExistingUsersOverview';
import { useSetAtom } from 'jotai';

const ManageUsersPage = () => {
  const { getIdTokenClaims } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  const refresh = useSetAtom(userRequestId);

  const { register, handleSubmit, reset } = useForm<NewUser>();
  const onSubmit = async (data: NewUser) => {
    const token = await getIdTokenClaims();
    const cleanUser: NewUser = {
      email: data.email.trim(),
      companyName: data.companyName?.trim(),
      name: data.name?.trim(),
    };

    const response = await fetch('/nf-functions/create-user', {
      method: 'POST',
      headers: {
        authorization: `Bearer ${token?.__raw}`,
      },
      body: JSON.stringify(cleanUser),
    });
    const jsonResponse = await response.json();
    if (response.status === 200) {
      enqueueSnackbar('Brukeren ble lagret', { variant: 'success' });
      refresh((x) => x + 1);
      reset();
    } else {
      const errorMessage = jsonResponse.feilmelding || 'Noe gikk galt under oppretting av brukeren. Prøv igjen senere.';
      enqueueSnackbar(errorMessage, { variant: 'warning' });
      console.error(response.status);
    }
  };

  return (
    <div>
      <Paper className={c.paper}>
        <h2>Legg til en ny bruker</h2>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <TextField
              {...register('email', { required: true })}
              label="E-postadresse"
              variant="outlined"
              margin="normal"
              className={c.textField}
              required
            />
            <TextField
              {...register('name', { required: true })}
              label="Navn"
              variant="outlined"
              margin="normal"
              className={c.textField}
              required
            />
            <TextField
              {...register('companyName')}
              label="Firmanavn"
              variant="outlined"
              margin="normal"
              className={c.textField}
            />
          </div>

          <Button variant="contained" color="primary" size="medium" type="submit" startIcon={<SaveIcon />}>
            Opprett bruker
          </Button>
        </form>
      </Paper>
      <React.Suspense
        fallback={
          <div className={c.loaderContainer}>
            <Loader />
          </div>
        }
      >
        <ExistingUsersOverview />
      </React.Suspense>
    </div>
  );
};

export default ManageUsersPage;

interface NewUser {
  email: string;
  name?: string;
  companyName?: string;
}
