import React, { useState } from 'react';
import { SoldCarsTable } from './SoldCarsTable';
import { Paper, Typography } from '@mui/material';
import s from './StatisticsPage.module.css';
import { getSavedAndSortedCars } from './atoms/savedCars';
import _ from 'lodash';
import Button from '@mui/material/Button';
import cx from 'classnames';
import { useAtomValue } from 'jotai';

const currencyNumberFormat = Intl.NumberFormat('no-NO', {
  style: 'currency',
  currency: 'NOK',
  maximumSignificantDigits: 11,
});

function format(number: number) {
  return currencyNumberFormat.format(Math.round(number));
}

const StatisticsPage = () => {
  const unfilteredCars = useAtomValue(getSavedAndSortedCars)?.filter((car) => car.isSold && car.numberOfBids > 0);

  const [locationFilters, setLocationFilters] = useState<string[]>([]);

  if (!unfilteredCars) return null;

  const numberOfCarsPerLocation = _.countBy(unfilteredCars, (c) => c.location);

  const cars =
    locationFilters.length === 0
      ? unfilteredCars
      : unfilteredCars.filter((car) => locationFilters.includes(car.location));

  const carsThatHavePricePaidFor = cars.filter((c) => !!c.pricePaidForOppkjoperCar);

  const sumOfFinalPrices = _.sumBy(cars, (c) => c.finalPrice ?? 0);
  const sumOfPricePaidForOppkjoperCars = _.sumBy(
    carsThatHavePricePaidFor,
    (c) => Number(c.pricePaidForOppkjoperCar) ?? 0
  );
  const sumOfMargins = _.sumBy(carsThatHavePricePaidFor, (c) => c.finalPrice! - c.pricePaidForOppkjoperCar!);
  const averageNumberOfBids = _.meanBy(cars, (c) => c.numberOfBids) || 0;
  const averagePricePaidFor = _.meanBy(carsThatHavePricePaidFor, (c) => c.pricePaidForOppkjoperCar) || 0;
  const averageFinalPrice = _.meanBy(cars, (c) => c.finalPrice) || 0;
  const averageMargin = sumOfMargins / carsThatHavePricePaidFor!.length || 0;

  return (
    <div>
      <Typography variant={'h1'}>Statistikk</Typography>
      <Paper className={s.section}>
        <Typography variant={'h2'}>Filtrering</Typography>
        <div className={s.filterSection}>
          {_.uniqBy(unfilteredCars, (car) => car.location)
            .map((c) => c.location)
            .map((location: string) => (
              <Button
                onClick={() => setLocationFilters((prevState) => _.xor(prevState, [location]))}
                variant="outlined"
                color="primary"
                size="medium"
                className={cx(s.filterButton, { [s.activeFilter]: locationFilters.includes(location) })}
                key={location}
              >
                {`${location} (${numberOfCarsPerLocation[location]})`}
              </Button>
            ))}
        </div>
      </Paper>
      <Paper className={s.section}>
        <Typography variant={'h2'}>Nøkkeltall</Typography>
        <div className={s.kpiSection}>
          <div className={s.kpiGroup}>
            <Typography>Antall solgte biler: {cars.length}</Typography>
            <Typography>Snitt antall bud: {averageNumberOfBids.toFixed(1)}</Typography>
          </div>
          <div className={s.kpiGroup}>
            <Typography>
              Sum innkjøpspris: {format(sumOfPricePaidForOppkjoperCars)} ({carsThatHavePricePaidFor?.length})
            </Typography>
            <Typography>Snitt innkjøpspris: {format(averagePricePaidFor)} </Typography>
          </div>
          <div className={s.kpiGroup}>
            <Typography>
              Sum oppnådde priser: {format(sumOfFinalPrices)} ({cars?.length})
            </Typography>
            <Typography>Snitt oppnådd pris: {format(averageFinalPrice)}</Typography>
          </div>
          <div className={s.kpiGroup}>
            <Typography>
              Sum fortjeneste: {format(sumOfMargins)} ({carsThatHavePricePaidFor?.length})
            </Typography>
            <Typography>Snitt fortjeneste: {format(averageMargin)}</Typography>
          </div>
        </div>
      </Paper>
      <SoldCarsTable cars={cars} />
    </div>
  );
};

export default StatisticsPage;
