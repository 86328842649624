import { DateTime } from 'luxon';
import { FaunaDocument } from '../types/FaunaDocument';
import { authToken } from './auth';
import { Car, ListCar } from '../types/Car';
import { atom } from 'jotai';
import { atomFamily, atomWithRefresh } from 'jotai/utils';

export interface sortOrderState {
  selector: (car: ListCar) => string | number | DateTime;
  reverse: boolean;
  name: string;
}

export const sortOrder = atom<sortOrderState>({
  selector: (car: ListCar) => car.title,
  reverse: false,
  name: 'name',
});

export const getSavedCars = atomWithRefresh<Promise<ListCar[] | undefined>>(async (get) => {
  const token = get(authToken)?.__raw;
  if (!token) return;
  const response = await fetch('/nf-functions/get-cars', {
    method: 'GET',
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
  return (await response.json()).data as ListCar[];
});

export const getSavedAndSortedCars = atom<Promise<ListCar[] | undefined>>(async (get) => {
  const cars = await get(getSavedCars);
  const sort = get(sortOrder);
  if (!cars) return undefined;

  return cars.slice().sort((a, b) => {
    const aSide = sort.selector(a);
    const bSide = sort.selector(b);

    if (aSide > bSide) return !sort.reverse ? 1 : -1;
    if (bSide > aSide) return !sort.reverse ? -1 : 1;
    return 0;
  });
});

export const getSavedCar = atomFamily((carId: string) =>
  atomWithRefresh(async (get) => {
    const token = get(authToken)?.__raw;
    if (!token) return;
    const response = await fetch(`/nf-functions/get-car/${carId}`, {
      method: 'GET',
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return (await response.json()) as FaunaDocument<Car>;
  })
);
