import { FormControlLabel, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid2';
import React from 'react';
import styles from './EquipmentList.module.css';
import { userHasOppkjoperRole } from './atoms/auth';
import { useAtomValue } from 'jotai';

function not(a: string[], b: string[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

const EquipmentList = ({
  includedEquipment,
  setIncludedEquipment,
}: {
  includedEquipment: string[];
  setIncludedEquipment: (list: string[]) => void;
}) => {
  const [customEquipment, setCustomEquipment] = React.useState<string>('');
  const [addedCustomEquipments, setAddedCustomEquipments] = React.useState<string[]>([]);

  const isOppkjoper = useAtomValue(userHasOppkjoperRole);

  const defaultAvailableEquipment = [
    //TODO: Load this from backend ?
    'Vinterhjul',
    'Tilhengerfeste',
    'Skinninteriør',
    'Elektrisk førersete',
    'Minnefunksjon førersete',
    'Elektrisk passasjersete',
    'Minnefunksjon passasjersete',
    'Varmeseter foran og bak',
    'Kjøling i seter',
    'Varme i ratt',
    'Premium lydanlegg',
    'Navigasjon',
    'Adaptiv Cruise Control',
    'Blindsonevarsler',
    'AutoHold',
    'Automatisk kjørelys',
    'Automatisk fjernlys',
    '360° Kamera',
    'Android Auto',
    'Apple CarPlay',
    'Gummimatter',
    'Metallic lakk',
    'Keyless',
    'Isofix',
    'App-styring',
    '12V-uttak',
  ];

  const oppkjoperDefaultAvailableEquipment = [
    'EU-godkjent',
    'Komplett servicehistorikk',
    'To hjulsett',
    'To nøkkelsett',
    'Tilhengerfeste',
    'Kun én eier',
  ];

  const applicableEquipmentList = isOppkjoper ? oppkjoperDefaultAvailableEquipment : defaultAvailableEquipment;

  React.useEffect(() => {
    const allreadyAddedCustomEquipment = not(includedEquipment, applicableEquipmentList);
    setAddedCustomEquipments(allreadyAddedCustomEquipment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const equipment = event.target.value;
    if (!includedEquipment.includes(equipment)) {
      setIncludedEquipment([...includedEquipment, equipment]);
    } else {
      setIncludedEquipment(includedEquipment.filter((x) => x !== equipment));
    }
  };

  const onSaveCustomEquipment = async () => {
    setIncludedEquipment([...includedEquipment, customEquipment]);
    setAddedCustomEquipments([...addedCustomEquipments, customEquipment]);
    setCustomEquipment('');
  };

  return (
    <div className={styles.container}>
      <Grid container spacing={2}>
        {applicableEquipmentList.map((equipment) => (
          <Grid key={equipment} size={{ xs: 12, md: 6, lg: 4 }}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={handleToggle}
                  value={equipment}
                  checked={includedEquipment.includes(equipment)}
                />
              }
              label={equipment}
            />
          </Grid>
        ))}
        {addedCustomEquipments.map((equipment) => (
          <Grid key={equipment} size={{ xs: 12, md: 6 }}>
            <Grid>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    onChange={handleToggle}
                    value={equipment}
                    checked={includedEquipment.includes(equipment)}
                  />
                }
                label={equipment}
              />
            </Grid>
          </Grid>
        ))}
      </Grid>

      <div className={styles.customEquipmentContainer}>
        <TextField
          label={isOppkjoper ? 'Legg til høydepunkt' : 'Legg til ekstrautstyr'}
          variant="outlined"
          margin="dense"
          className={styles.customEquipmentTextField}
          value={customEquipment}
          onChange={(e) => setCustomEquipment(e.target.value)}
        />
        {customEquipment.length > 0 && (
          <Button variant="contained" color="primary" size="small" onClick={onSaveCustomEquipment}>
            Legg til
          </Button>
        )}
      </div>
    </div>
  );
};

export default EquipmentList;
